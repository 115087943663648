import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  InputAdornment,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  IconButton,
  MenuItem
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { AddLegtTypeButton, adminSelectProps, CSSPropertiesMap, CustomFormSelectNew, CustomFormTextField, ErrorBoxes, FilterTypeButton, Footer, FooterCancelSaveChanges, FormSubmitButton, GoldishSimpleButton, InputTagForFileUpload, ReusableFormLabel, SearchTextFieldActiveBooking, StyledPagination, updateArrayElement } from "../../../components/src/MuiCustomComponents";
import { Add, ArrowBack, DeleteOutline, FilterList, KeyboardArrowDown, Search, SwapVert } from "@material-ui/icons";
import {theme as colors} from "../../../components/src/utils";
import { UploadAircraftIcon } from "../../../components/src/assets";
import { Formik, FormikErrors, FormikProps, FormikTouched } from "formik";
import { catalogueFormSchema } from "../../../components/src/Validations";
import {CatalogueFormType} from "./CatalogueController";
import { v4 as uuidv4 } from 'uuid';


const headerArray = [
  {title:'Aircraft ID',value:'account_id'},
  {title:'Aircraft Name',value:'name'},
  {title:'Aircraft Category',value:'email_address'},
  {title:'Date Created',value:'job'},
  {title:'',value:'account_type'}
]



// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Table Parent
  renderCatalogueTableCont=()=>{
    return <Box className="dash_main">
      {this.renderCatalogueHead()}
      {this.renderCatalogueTable()}
      {this.renderPaginationComponent()}
    </Box>
  }

  // Table Catalogue Header
  renderCatalogueHead=()=>{
    return <Grid container justifyContent="space-between" style={webStyle.headerParentStyle}>
    <Grid item lg={2} sm = {3} >
    <GoldishSimpleButton style={{...webStyle.headerButtonStyle,textTransform:'capitalize'}}>Aircraft Catalogue</GoldishSimpleButton>
    </Grid>
    <Grid item lg={6} justifyContent="center">
      <SearchTextFieldActiveBooking
        fullWidth
        placeholder={'Enter aircraft name or category...'}
        style={{ position: "relative", left: "-10px" ,width:'80%'}}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <Search style={{ color: "#757677" }} />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
    <Grid item xl={2} lg={3} md={4} sm={12}>
      <Box style={webStyle.filterAddCont}>
        <AddLegtTypeButton className="add_aircraft"  style={{fontSize:"1rem"}} onClick={()=>this.handleFormsAndTable(false,false,true,false)}>+ Add New Aircraft</AddLegtTypeButton>
        <FilterTypeButton><FilterList style={{ fontSize: "2rem" }} /></FilterTypeButton>
      </Box>
    </Grid>
  </Grid>
  }

  // Table Catalogue
  renderCatalogueTable=()=>{
    return <TableContainer style={webStyle.tableCont}>
    <Table style={{borderCollapse:"separate"}}>
      {this.renderCatalogueTableHeader()}
      <Box height={'15px'}/>
      {this.renderTableContent()}
    </Table>
  </TableContainer>
  }
  
  // Header Mapped
  renderCatalogueTableHeader=()=>{
    return <TableHead>
      <TableRow className="dash_table_head" style={{height:"auto"}}>
        {headerArray.map((elem)=>this.renderCatHeadCell(elem))}
      </TableRow>
    </TableHead>
  }

    // Header Each Cell
  renderCatHeadCell=(head:{title:string,value:string})=>{
    const {title} = head;
    return <TableCell style={{borderRadius:"0"}}>
      {title && <Box className="order_detail_table_head_cell">
            <Box>{title}</Box>
            <Box id="order_detail_view_menu_sort_button">
              <SwapVert style={{color : colors.GOLD_COLOR}} />
            </Box>
      </Box>}
    </TableCell>
  }

  // Table Each Row
  renderEachRowCatalogue=()=>{
    return <>
      <TableRow className="dash_table_row order_details_row" >
        <TableCell className="dash_rounded-row-first-cell">Heavy Aircraft</TableCell>
        <TableCell style={{textOverflow:'ellipsis',maxWidth:"0rem",overflow:"hidden"}}>Heavy Aircraft</TableCell>
        <TableCell style={{textOverflow:'ellipsis',maxWidth:"0rem",overflow:"hidden"}}>Heavy Aircraft</TableCell>
        <TableCell style={{textOverflow:'ellipsis',maxWidth:"0rem",overflow:"hidden"}}>Heavy Aircraft</TableCell>
        <TableCell className={`dash_rounded-row-last-cell`}>
          <Box style={webStyle.viewDetail}>
              <AddLegtTypeButton className="aircraft_row" onClick={()=>this.handleFormsAndTable(true,false,false,false)} style={{height : '2.1rem',fontSize:'1rem'}}>View Details</AddLegtTypeButton>
          </Box>
        </TableCell>
      </TableRow>
      <Box height={'0.5rem'}/>
    </>
  }

  // Table Code with all rows
  renderTableContent=()=>{
   return <>
    {this.renderEachRowCatalogue()}
    {this.renderEachRowCatalogue()}
    {this.renderEachRowCatalogue()}
    {this.renderEachRowCatalogue()}
   </>
  }

  // Pagination of table
  renderPaginationComponent = () => {
    return <Box marginTop={'1rem'}>
      <StyledPagination count={3} page={1} shape="rounded" />
    </Box>

  }


  renderMainContainer=()=>{
    const {isTableMode} = this.state;
    return <Box style={{color:'white'}}>
       {isTableMode ? <Box className="catalogue_parent">{this.renderCatalogueTableCont()}</Box> : this.renderCatalogueVieEditParent()} 
    </Box>
  }

  renderCatalogueVieEditParent=()=>{
    return <>
        <Box className="booking_section" marginBottom={'7rem'}>
          {this.renderCatalogueStateHeading()}    
          {this.renderCatalogueGrid()}
          {this.renderCatalogueFooter()}
        </Box>
    </>
  }

  renderCatalogueFooter=()=>{
    const {isViewMode} = this.state;
    return <Footer>
        {isViewMode ? this.renderViewStateFooter() : this.renderEditStateFooter()}
    </Footer>
  }

  renderViewStateFooter=()=>{
   return <Box className="profile_submission">
      <AddLegtTypeButton className = 'delete_account' style={{width:'50rem',color:'#F87171',border:'1px solid #F87171',backgroundColor:'#1A1C1E'}} fullWidth>Delete Aircraft</AddLegtTypeButton>
      <FormSubmitButton onClick={()=>{this.handleFormsAndTable(false,true,false,false)}} style={{width:'50rem'}} fullWidth  className="edit_aricraft">Edit Aircarft</FormSubmitButton>
    </Box>
  }

  renderEditStateFooter=()=>{
    return  <FooterCancelSaveChanges submit={()=>this.catalogueFormik?.handleSubmit()} cancel={()=>this.handleBackSteps()} />
  }


  renderCatalogueStateHeading=()=>{
    const {isEditMode,isAddMode} = this.state;
    const aircraftName = " Challenger 650 Details";
    const viewEdit = isEditMode ? "Edit" : '';
    const mainHeading = isAddMode ? "Add New Aircraft" : viewEdit + aircraftName;
    return <GoldishSimpleButton className='back_head' style={{ color: 'white', textTransform: "capitalize", justifyContent:'start',fontSize:'1.6rem'}} onClick={()=> this.handleBackSteps()}>
      <ArrowBack className = 'cf_avinod_arrow_search' />
      {mainHeading}
    </GoldishSimpleButton>
  }

  renderCatalogueGrid=()=>{
    const {catalogueInitialValue, isViewMode} = this.state;
    return <Formik
    initialValues={catalogueInitialValue}
    validationSchema={catalogueFormSchema}
    onSubmit={(_values) => {}}
    innerRef={(formikProps) => {
      this.catalogueFormik = formikProps as FormikProps<unknown>
    }}
    key={isViewMode ? 1 : 2}
    validateOnChange
    validateOnBlur
    enableReinitialize
  >
    {(formikProps) => (
          <Grid container spacing={2} style={{rowGap:"1rem"}}>
          <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
            {this.renderAircraftImageParent(formikProps)}
          </Grid>
          <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
            {this.renderCatalogueFormParent(formikProps)}
          </Grid>
        </Grid>
    )}</Formik>
  }

  renderCatalogueFormTextField=(placholder:string,keyName:keyof CatalogueFormType,value:CatalogueFormType,handleChange:(event: React.ChangeEvent<HTMLInputElement>) => void,touched:FormikTouched<CatalogueFormType>,errors:FormikErrors<CatalogueFormType>)=>{
    const {isViewMode} = this.state;
    const variant = isViewMode ? 'standard' : 'outlined'
    return <> <CustomFormTextField
    autoComplete="off"
    placeholder={`Enter ${placholder}`}
    isFocusedTypeInput={true}
    variant={variant}
    size="medium"
    fullWidth
    InputProps={{
    inputProps:{style: {marginLeft:isViewMode ? '0.3rem' : '-0.5rem'}}
  }}
    name={keyName}
    id={keyName}
    value={value[keyName]}
    onChange={handleChange}
    disabled={isViewMode}
    style={isViewMode ? webStyle.disableBackground : {}}
    isErrorTouch = {touched[keyName] && errors[keyName]}
    onFocus={()=>{this.handleFocusLable(keyName)}}
    onBlur={this.handleFocusLable}
  />   
  <ErrorBoxes errors={errors[keyName] as unknown as string} message={errors[keyName] as unknown as string} touched={touched[keyName] as unknown as boolean}/>
   </>
  }

  renderCatalogueFormParent=(formikProps:FormikProps<CatalogueFormType>)=>{
    const {touched,errors,values,handleChange} = formikProps;
    const {focusLabel, isViewMode} = this.state;
    const variant = isViewMode ? 'standard' : 'outlined'
    return <Box style={webStyle.eachGrid}>
          <Box style={{...webStyle.formContainer,rowGap:'1.5rem'}} className="booking_section">
          {isViewMode && <Box>
            <ReusableFormLabel label="Aircraft ID"/> 
            {this.renderCatalogueFormTextField('Aircraft ID','id' as unknown as keyof CatalogueFormType,values,handleChange,touched,errors)} 
          </Box>}

          <Box>
            <ReusableFormLabel touchError={touched.aircraft_name} error={errors.aircraft_name} label="Aircraft Name" focusedLabel={focusLabel} keyName="aircraft_name"/>
            {this.renderCatalogueFormTextField('Aircraft Name','aircraft_name',values,handleChange,touched,errors)}
          </Box>

          <Box>
            <ReusableFormLabel touchError={touched.aircraft_manufacturer} error={errors.aircraft_manufacturer} label="Aircraft Manufacturer" focusedLabel={focusLabel} keyName="aircraft_manufacturer"/>
            {this.renderCatalogueFormTextField('Aircraft Manufacturer','aircraft_manufacturer',values,handleChange,touched,errors)}

          </Box>

          <Box>
            <ReusableFormLabel touchError={touched.aircraft_category} error={errors.aircraft_category} label="Aircraft Category" focusedLabel={focusLabel} keyName="aircraft_category"/>
            <FormControl
            className="mui_select_unkownn-1"
            fullWidth 
          >
            <CustomFormSelectNew
            onFocus={() => {this.handleFocusLable("aircraft_category")}}
            onBlur={this.handleFocusLable}
            variant={variant}
            className='aircarft_cat'
            inputProps={{
              IconComponent: !isViewMode ? KeyboardArrowDown : "s",
            }}
            isError={(touched.aircraft_category && errors.aircraft_category) as unknown as boolean}
            disabled={isViewMode}
            value={values.aircraft_category}
            name='aircraft_category'
            onChange={handleChange}
            fullWidth
            MenuProps={{...adminSelectProps,
              style:{marginTop:'5px',zIndex:6},
              className: "advance_search_picker job_title_picker",
            }}
            style={{
            color : values.aircraft_category === 'none' ? '#858789' : 'white',
            backgroundColor: isViewMode ? '#2D3034' : '#1A1C1E',
            padding : isViewMode ? '0 1rem' : 'inherit',
            fontWeight:600
            }}
          >
              <MenuItem value={'none'} style={{display:'none'}}>Select...</MenuItem>
          </CustomFormSelectNew>

          </FormControl>
          <ErrorBoxes errors={errors.aircraft_category} message={errors.aircraft_category} touched={touched.aircraft_category}/>
          </Box>

          <Box>
            <ReusableFormLabel touchError={touched.aircraft_passenger} error={errors.aircraft_passenger} label="Max Passengers" focusedLabel={focusLabel} keyName="aircraft_passenger"/>
            {this.renderCatalogueFormTextField('Max Passengers','aircraft_passenger',values,handleChange,touched,errors)}
  
          </Box>

          <Box>
            <ReusableFormLabel touchError={touched.luggage_capacity} error={errors.luggage_capacity} label="Luggage Capacity" focusedLabel={focusLabel} keyName="luggage_capacity"/>
            {this.renderCatalogueFormTextField('Luggage Capacity','luggage_capacity',values,handleChange,touched,errors)}
  
          </Box>

          <Box>
            <ReusableFormLabel touchError={touched.year_of_manufacturer} error={errors.year_of_manufacturer} label="Year of Manufacture" focusedLabel={focusLabel} keyName="year_of_manufacturer"/>
            {this.renderCatalogueFormTextField('Year of Manufacture','year_of_manufacturer',values,handleChange,touched,errors)}
          </Box>

          <Box>
            <ReusableFormLabel touchError={touched.year_of_refurb} error={errors.year_of_refurb} label="Year of Refurb" focusedLabel={focusLabel} keyName="year_of_refurb"/>
            {this.renderCatalogueFormTextField('Year of Refurb','year_of_refurb',values,handleChange,touched,errors)}
          </Box>

          </Box>    
    </Box>
  }

  renderAircraftImageParent=(formikProps:FormikProps<CatalogueFormType>)=>{
    return <Box style={{...webStyle.eachGrid,...webStyle.imageGridParent}} className="booking_section">
        <Box style={webStyle.photoText} className="cf_avinod_grey_text">Photos of your listing </Box>
        <Box>Max 6MB each (Recommended size: 1024*610)</Box>
        {this.renderAirCraftImageGridParent(formikProps)}
    </Box>
  }

  renderAirCraftImageGridParent=(formikProps:FormikProps<CatalogueFormType>)=>{
    const {gridArray} = this.state;
    return <Grid container spacing={2} style={{rowGap:'1rem',marginTop:'1rem',width:'85%'}}>
      {gridArray.map((_,imgIndex:number)=>this.renderEachImageGrid(formikProps,imgIndex))}
    </Grid>
  }

  renderEachImageGrid=(formikProps:FormikProps<CatalogueFormType>,imgIndex:number)=>{
    const isFirstGrid = imgIndex === 0;
    const {values,setFieldValue} = formikProps;
    const {aircarft_images} = values;
    const {isViewMode} = this.state;
    return <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={uuidv4()}>
      <Box style={webStyle.aircraftImageParent}>
          {isFirstGrid && this.renderMainImageTag()}
          {aircarft_images[imgIndex] && this.renderDeleteIcon(formikProps,imgIndex)}
          <InputTagForFileUpload refer={this.fileInputRefs[imgIndex]} handleChange={(event)=>{setFieldValue('aircarft_images',updateArrayElement(aircarft_images,imgIndex,event.target.files![0]))}}/>
          {!isViewMode && this.renderImageAndUpload(aircarft_images[imgIndex],imgIndex)}
      </Box>
    </Grid>
  }

  renderMainImageTag=()=>{
    return <Box style={webStyle.mainImageTag}>Main Image</Box>
}

  renderDeleteIcon=(formikProps:FormikProps<CatalogueFormType>,imgIndex:number)=>{
    const {setFieldValue,values} = formikProps;
    const {aircarft_images} = values;
    return <Box style={webStyle.delete_cont}>
      <IconButton className="delete_image" onClick={()=>setFieldValue('aircarft_images',updateArrayElement(aircarft_images,imgIndex,null))} style={webStyle.del_button}><DeleteOutline style={{fontSize:'2rem'}}/></IconButton>
    </Box>
  }

  renderUploadImageTextAndIcon=(imgIndex:number)=>{
    return <Box style={webStyle.aircraftImage}>   
        <IconButton style={{padding:'6px'}}>{imgIndex == 0 ? <UploadAircraftIcon/> : <Add style={{color:"#ACB5BB",fontSize:'3rem'}}/>}</IconButton>   
        <Box fontWeight={'bold'}>Upload Image</Box>
    </Box>
  }

  renderImageAndUpload=(imageInfo:File,imgIndex:number)=>{
    const isFirstGrid = imgIndex === 0;
    return <>
    {!imageInfo ? <Box className="upload_image" onClick={()=>this.handleUploadClick(imgIndex)} style={{marginTop:isFirstGrid ? '0.5rem' : 'inherit'}}>{this.renderUploadImageTextAndIcon(imgIndex)}</Box>:this.renderImage(imageInfo)
    }
    </> 
  }

  renderImage=(imageInfo:File)=>{
    return <>
      {typeof imageInfo === 'object' ? <img src={this.handleImageSrc(imageInfo)} style={webStyle.imageStyle}/> : <></>} 
    </>
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <Box className="catalogue_parent">{this.renderMainContainer()}</Box>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle:CSSPropertiesMap = {
  headerParentStyle : {
    alignItems:'center',
    rowGap:'1rem'
  },
  headerButtonStyle:{
    justifyContent: "start",
    fontSize:"1.2rem",
    color:'white'
  },
  filterAddCont:{
    display : 'flex',
    columnGap : '1.5rem',
    width : '100%',
    alignItems:'center',
    justifyContent:'end'
  },
  tableCont:{
    marginTop : '2rem'
  },
  viewDetail:{
    display:'flex',
    justifyContent:'center',
    width :"100%"
  },
  eachGrid:{
    borderRadius:"10px",
    backgroundColor:'black',
    padding:'1rem'
  },
  imageGridParent:{
    display:'flex',
    flexDirection:'column',
    rowGap:'1rem',
    alignItems:'center',
    padding:'1.5rem'
  },
  photoText:{
    fontSize:'1.4rem'
  },
  aircraftImageParent:{
    border:"1px dashed white",
    height:'11rem',
    borderRadius:'10px',
    overflow:'hidden',
    position:'relative',
  },
  mainImageTag:{
    padding : '1rem 0rem',
    backgroundColor:'#1A1C1E',
    textAlign:'center',
    color:'#6C7278',
    width:'100%',
    position:'absolute',
  },
  aircraftImage:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    width:"100%",
    height:'100%',
    rowGap:'0.5rem'
  },
  formContainer:{
    padding:'1rem',
    backgroundColor:'#1A1C1E',
    borderRadius:'10px'
  },
  disableBackground:{
    color:'white',
    borderRadius:'10px',
    backgroundColor : '#2D3034'
  },
  imageStyle:{
    objectFit:'cover',
    width:'100%',
    height:'100%'
  },
  delete_cont:{
    backgroundColor:'#1A1C1E',
    borderRadius:'10px',
    position: "absolute",
    bottom: "10px",
    right: "10px", 
  },
  del_button:{
    padding:'8px',
    color:'#F87171'
  }
}
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import React from "react"
import { FormikProps } from "formik";
export interface CatalogueFormType {
  aircraft_name : string | null
  aircraft_manufacturer : string | null
  aircraft_category : string | null
  aircraft_passenger : number | null
  luggage_capacity : number | null
  year_of_manufacturer : number | null
  year_of_refurb : number | null
  aircarft_images:[],
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  isTableMode:boolean
  isViewMode:boolean
  isEditMode:boolean
  isAddMode:boolean
  catalogueInitialValue:CatalogueFormType
  focusLabel:string
  gridArray:null[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  // Customizable Area Start
  catalogueFormik:null | FormikProps<unknown> = null;
  fileInputRefs:React.RefObject<HTMLInputElement>[] = Array.from({ length: 9 }, () => React.createRef())
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      isTableMode:true,
      isViewMode:false,
      isEditMode:false,
      isAddMode:false,
      catalogueInitialValue:{
        aircraft_name : null,
        aircraft_manufacturer : null,
        aircraft_passenger : null,
        luggage_capacity : null,
        year_of_manufacturer : null,
        year_of_refurb : null,
        aircraft_category : 'none',
        aircarft_images:[],
      },
      focusLabel:"",
      gridArray:Array(9).fill(null)
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      // let token = localStorage.getItem('authToken');
      this.setState({ token: token });
      this.getListRequest(token);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleFocusLable=(focusLabel="")=>{
    this.setState({focusLabel})
  }

  handleFormsAndTable=(isViewMode=this.state.isViewMode,isEditMode=this.state.isEditMode,isAddMode=this.state.isAddMode,isTableMode=this.state.isAddMode)=>{
    this.setState({isViewMode,isEditMode,isAddMode,isTableMode})
  }

  handleBackSteps=()=>{
    const {isEditMode} = this.state;
    if (!isEditMode) this.handleFormsAndTable(false,false,false,true)
    else this.handleFormsAndTable(true,false,false,false)
  }

  handleUploadClick = (imgIndex:number) => {
    this.fileInputRefs[imgIndex]?.current?.click();
  };

  handleImageSrc=(imageInfo:File)=>{
    if(typeof imageInfo === 'object') return URL.createObjectURL(imageInfo);
    return '';
  }

  // Customizable Area End
}

import React from "react";

// Customizable Area Start
import { Box, Button, TextareaAutosize, Typography, styled } from "@material-ui/core"
import { CustomBox, CustomTypography } from "../../../components/src/MuiCustomComponents";
import "react-quill/dist/quill.snow.css";
import "../src/TermsConditions.css"
import ReactQuill from "react-quill";
const CustomDashboardButton = styled(Button)({
  backgroundImage: `linear-gradient(#FFD078, #FFE3AF)`,
  color: "black",
  width: "287px",
  backgroundColor: "#FFD078",
  '&:hover': {
    backgroundColor: '#FFD078',
  },
  textTransform: "none",
  height: "56px",
  fontSize: "18px",
  fontWeight: "bold",
  borderRadius: "8px"
});
const MainContain = styled(Box)({});

const EditorBox = styled(Box)({
  "& .ql-editor": {
    "&:focus": {
      border: '2px solid #FFD27E',
    }
  }
});

const CancelButton = styled(Button)({
  color: "#FFF",
  backgroundColor: "#2D3034",
  '&:hover': {
    backgroundColor: '#2D3034',
  },
  width: "287px",
  textTransform: "none",
  height: "56px",
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "sans-serif",
  marginRight: 40,
  borderRadius: "8px"

});

const CustomTextarea = styled(TextareaAutosize)({
  backgroundColor: 'transparent',
  width: '100%',
  border: '1px solid #3E4247',
  borderRadius: 8,
  fontSize: '24px',
  fontWeight: 400,
  color: 'white',
  padding: '14px',
  resize: 'none',
  outline: 'none',
  overflowY: 'auto',
  '&:focus': {
    borderColor: '#FFD27E', 
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none', 
  'scrollbarWidth': 'none',
});


// Customizable Area End

import TermsConditionsEditController, {
  Props,
  configJSON,
} from "./TermsConditionsEditController";

export default class TermsConditionsEdit extends TermsConditionsEditController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "bullet" }, { list: "ordered" }]
    ]
  };
  formats = ["bold", "italic", "underline", "list", "bullet"];
  // Customizable Area End

  render() {
    // Customizable Area Start   
    const {title, termsConds} = this.state;
    const screenType = this.handleHeading();
    const isFAQType = screenType === 'Add FAQ' || screenType === 'Edit FAQ';
    const isDisabled = isFAQType && (title.length === 0 || termsConds.length === 0)
    return (
      <>
        <Box style={webStyles.heading}>
          <Box style={webStyles.backIcon} onClick={this.handleBack}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
              <path d="M23.4898 13.7562H9.52734L15.6273 7.65624C16.1148 7.16874 16.1148 6.36874 15.6273 5.88124C15.3938 5.64717 15.0767 5.51563 14.7461 5.51563C14.4154 5.51563 14.0984 5.64717 13.8648 5.88124L5.62734 14.1187C5.13984 14.6062 5.13984 15.3937 5.62734 15.8812L13.8648 24.1187C14.3523 24.6062 15.1398 24.6062 15.6273 24.1187C16.1148 23.6312 16.1148 22.8437 15.6273 22.3562L9.52734 16.2562H23.4898C24.1773 16.2562 24.7398 15.6937 24.7398 15.0062C24.7398 14.3187 24.1773 13.7562 23.4898 13.7562Z" fill="#FFF" />
            </svg>
          </Box>
          <Box>
            <CustomTypography style={{color:'white'}}>{this.handleHeading()}</CustomTypography>
          </Box>
        </Box>
        <MainContain>
          <CustomBox>
          {isFAQType && <> <Typography style={webStyles.title}>Enter Question</Typography>
            <CustomTextarea maxRows={3} minRows={1} onChange={(event) => this.handleTitleChange(event.target.value)} value={this.state.title} id="textArea" placeholder = 'Enter Question here...'/></> }
            <EditorBox style={{border: 'none', color: "white" }}>
              <span style={webStyles.answer}>Enter {!isFAQType ? 'Text' : 'Answer'}</span>
              <ReactQuill
                theme="snow"
                value={this.state.termsConds}
                onChange={this.handleEditorChange}
                modules={this.modules}
                formats={this.formats}
                placeholder = 'Enter your answer here...'
                style={{
                  height: 'auto',
                  ...webStyles.text,
                  wordBreak: 'break-all',
                }}
                id="mdEditor"
              />
            </EditorBox>
          </CustomBox>
        </MainContain>
        <Box style={webStyles.footer}>
          <CancelButton onClick={this.handleBack} id="btnSendTermsConds">
            Cancel
          </CancelButton>
          <CustomDashboardButton size="large" variant="outlined" id="savebutton" onClick={()=>this.handleSaveChanges()} disabled={isDisabled}>
            Save Changes
          </CustomDashboardButton>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    flex: 1,
    margin: 20,
  },
  title: {
    fontFamily: 'Manrope',
    fontWeight: 800,
    fontSize: "18px",
    color: "#94A3B8",
    marginBottom: "10px",
  },
  heading: {
    display: "flex",
    alignItems: "center",
    gap: "10px"
  },
  backIcon:{
    cursor: "pointer"
  },
  answer: {
    fontFamily: 'Manrope',
    fontWeight: 800,
    fontSize: "18px",
    color: "#94A3B8",
    marginBottom: "10px",
    position: "relative" as const,
    top: "30px"
  },
  text: {
    color: "#AEAEB2",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Manrope",
  },
  Box: {
    display: "flex",
    flexDirection: "column" as const,
    gap: "25px"
  },
  footer: {
    padding: 30, width: 'calc(100vw - 350px)',
    position: "fixed" as const,
    bottom: 0, right: 0,
    display: 'flex',
    justifyContent: 'center',
    borderTop: "1px solid var(--neutrals-cool-gray-700, #334155)"
  },
};
// Customizable Area End

// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import {Box} from "@material-ui/core";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import Sidebar from "../../blocks/navigationmenu/src/Sidebar.web";

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import {Route } from "react-router-dom";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Cfdebitskycard3 from "../../blocks/cfdebitskycard3/src/Cfdebitskycard3";
import Chatgpt3 from "../../blocks/chatgpt3/src/Chatgpt3";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import OrderDetailsView from "../../blocks/ordermanagement/src/OrderDetails";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch.web";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Notifications from "../../blocks/notifications/src/Notifications";
import Location from "../../blocks/location/src/Location";
import Cfavinodeintegration2 from "../../blocks/cfavinodeintegration2/src/Cfavinodeintegration2.web";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Cfpaymentgateway33 from "../../blocks/cfpaymentgateway33/src/Cfpaymentgateway33";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Contactus from "../../blocks/contactus/src/Contactus.web";
import FAQ from "../../blocks/contactus/src/FAQ.web";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Guestlogin2 from "../../blocks/guestlogin2/src/Guestlogin2";
import DownloadOptions from "../../blocks/downloadoptions/src/DownloadOptions";
import Addresses from "../../blocks/addressmanagement/src/Addresses";
import AddAddress from "../../blocks/addressmanagement/src/AddAddress";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import Videos from "../../blocks/videos/src/Videos";
import Cf25hourskycard from "../../blocks/cf25hourskycard/src/Cf25hourskycard";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions.web";
import TermsConditionsEdit from "../../blocks/termsconditions/src/TermsConditionsEdit.web"
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import MasterLayout from "../../components/src/MasterLayout";
import Jobtitles from '../../blocks/adminconsole2/src/Jobtitles.web';
import AdminList from '../../blocks/adminconsole2/src/AdminList.web';
import Cfcustomskycards from "../../blocks/cfcustomskycards/src/Cfcustomskycards.web"
import { loginRoute, registerRoute } from "../../components/src/utils";


const routeMap = {
Chatgpt3:{
 component:Chatgpt3,
path:"/Chatgpt3"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
OrderDetailsView:{
 component:OrderDetailsView,
path:"/OrderDetailsView",
user_type : ['user']},
PreviousBookings:{
  component:OrderDetailsView,
 path:"/PreviousBookings",
 user_type : ['user']},
 Clients:{
  component:OrderDetailsView,
 path:"/Clients",
 user_type : ['user']},
 OrderManagement:{
  component:OrderManagement,
 path:"/OrderManagement",
 user_type : ['user']},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch",
user_type : ['user']},
Jobtitles:{
  component:Jobtitles,
 path:"/JobTitles",
 user_type : ['user']},
 AdminList:{
  component:AdminList,
 path:"/AdminList",
 user_type : ['user']},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2",
user_type : ['user']},
Notifications:{
 component:Notifications,
path:"/Notifications",
user_type : ['user']},
Location:{
 component:Location,
path:"/Location"},
Cfavinodeintegration2:{
 component:Cfavinodeintegration2,
path:"/Cfavinodeintegration2",
user_type:['user']},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue",
user_type:['user']},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock",
user_type:['user']},
AdminProfile:{
  component:UserProfileBasicBlock,
 path:"/AdminProfile",
 user_type:['user']},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Cfpaymentgateway33:{
 component:Cfpaymentgateway33,
path:"/Cfpaymentgateway33"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Contactus:{
 component:Contactus,
path:"/Contactus",
user_type: ["user"]},
ClientContactus:{
  component:Contactus,
 path:"/ClientContactus",
 user_type: ["user"]},
FAQ:{
  component:FAQ,
 path:"/FAQ",
 user_type: ["user"]},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Settings2:{
 component:Settings2,
path:"/SystemSettings",
user_type: ["user"]},
Guestlogin2:{
 component:Guestlogin2,
path:"/Guestlogin2"},
DownloadOptions:{
 component:DownloadOptions,
path:"/DownloadOptions"},
Addresses:{
 component:Addresses,
path:"/Addresses"},
AddAddress:{
 component:AddAddress,
path:"/AddAddress"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:`${loginRoute}`,
user_type:[]
},
Videos:{
 component:Videos,
path:"/Videos"},
Cf25hourskycard:{
 component:Cf25hourskycard,
path:"/Cf25hourskycard"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword",
user_type:[]},
CreateClient:{
  component:ForgotPassword,
 path:"/CreateUser",
 user_type:[]},
 UpdatePassword:{
  component:ForgotPassword,
 path:"/UpdatePassword",
 user_type:[]},
 UpdateEmail:{
  component:ForgotPassword,
 path:"/UpdateEmail",
 user_type:[]},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Chat:{
 component:Chat,
path:"/Chat",
user_type: ["user"]},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions",
user_type: ["user"]
},
TermsConditionsEdit:{
  component:TermsConditionsEdit,
 path:"/TermsConditionsEdit",
 user_type: ["user"]
 },
 PrivacyPolicyEdit:{
  component:TermsConditionsEdit,
 path:"/PrivacyPolicyEdit",
 user_type: ["user"]
 },
 AddFAQ:{
  component:TermsConditionsEdit,
 path:"/AddFAQ",
 user_type: ["user"]
 },
 EditFAQ:{
  component:TermsConditionsEdit,
 path:"/EditFAQ",
 user_type: ["user"]
 },
PrivacyPolicy:{
  component:TermsConditions,
 path:"/PrivacyPolicy",
 user_type: ["user"]
 },
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:`${registerRoute}`,
user_type:[]},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},

  Home: {
component:Dashboard,
    path: '/',
    exact: true,
    user_type: ["user"]
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  Viewcustomskycards:{
    component:Cfcustomskycards,
   path:"/Viewcustomskycards",
  user_type:["user"]},
  Managecustomskycards:{
    component:Cf25hourskycard,
   path:"/Managecustomskycards",
  user_type:["user"]},
  Customskycardspurchases:{
    component:Cfcustomskycards,
   path:"/Customskycardspurchases",
  user_type:["user"]},
  Viewdebitskycard3:{
    component:Cfcustomskycards,
   path:"/Viewdebitskycard3",
  user_type:["user"]},
  Managedebitskycard3:{
    component:Cf25hourskycard,
   path:"/Managedebitskycard3",
  user_type:["user"]},
  View25hourskycard:{
    component:Cfcustomskycards,
   path:"/View25hourskycard",
  user_type:["user"]},
  Manage25hourskycard:{
    component:Cf25hourskycard,
   path:"/Manage25hourskycard",
  user_type:["user"]},
  DebitskycardDescription:{
    component:Cfdebitskycard3,
   path:"/DebitskycardDescription",
   user_type:["user"]
  },
  CustomskycardDescription:{
    component:Cfdebitskycard3,
   path:"/CustomskycardDescription",
   user_type:["user"]
  },
  Cf25skycardDescription:{
    component:Cfdebitskycard3,
   path:"/Cf25skycardDescription",
   user_type:["user"]
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

const getCurrentRouteDetails = ()=>{
  let user_type = null;
  const {location:{pathname}} = document
  for (let key in routeMap) {
    if (routeMap[key].path.toLowerCase() === pathname.toLowerCase()) {
      user_type = routeMap[key];
      break;
    }
  }
  return user_type;
}

class App extends Component {
   
  render() {
    
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    const isLoggedIn =  localStorage.getItem('token');
    const screens = WebRoutesGenerator({ routeMap });
    let current_route = getCurrentRouteDetails();
    let allowed_route = current_route?.user_type;

     
    if (isLoggedIn) {
      if (allowed_route?.includes("user")) {
        return (
          <>
          <Route render={(props)=>{
          const {history:{push}}  = props;
          return <NavigationMenu screen={screens} history={props.history} navigation={{navigate:push}} />}
          }/>            
          </>
        );
      } else if (allowed_route?.length === 0) {
        document.location.pathname = '/UserProfileBasicBlock';
      }
    } else {
      if (allowed_route?.length === 0) {
        return <MasterLayout>{screens}</MasterLayout>;
      } else {
        document.location.pathname = `${loginRoute}`;
      }
    }
     
  }
}

export default App;
